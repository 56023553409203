import React, { useState } from 'react'
import { UserContext } from 'components/context'
import { Highlights, PinInfo, Workouts } from './components'
import PTPackage from '../PT/PTPackage'
import './slide.css'

const IndexInfo = () => {
  const { membership } = React.useContext(UserContext)
  const [slideUpOrDown, setSlideUpOrDown] = useState('down')

  const slideDown = (ev) => {
    setSlideUpOrDown('down')
  }

  const slideUp = (ev) => {
    setSlideUpOrDown('up')
  }

  if (!membership) return null

  if (membership.keys.find((k) => k.type.toLowerCase() === 'rfid')) {
    return (
      <div id={'index-info'}>
        <div className={'slide ' + slideUpOrDown}>
          <Workouts />
          <Highlights />
        </div>

        <PTPackage onEnd={slideDown} onStart={slideUp} />

      </div>
    )
  }

  return (
    <div id={'index-info'}>
      <div className={slideUpOrDown}>
        <Workouts />
        <PinInfo />
        <Highlights />
      </div>
      <PTPackage onEnd={slideDown} onStart={slideUp} />
    </div>
  )
}

export default IndexInfo
