import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext } from 'components/context'
import { dateToLocale } from 'helpers'
import moment from 'moment'
import { ActionButton } from 'components/Buttons'

const PlaceholderOption = ({ type }) => {
  const { t } = React.useContext(GlobalContext)

  return (
    <option value={''} disabled>
      {t(`pages.freeze.form.placeholder_${type}`)}
    </option>
  )
}

PlaceholderOption.propTypes = {
  type: PropTypes.string.isRequired,
}

const Option = ({ date }) => {
  const { locale } = React.useContext(GlobalContext)
  return (
    <option value={date}>
      { dateToLocale(date, locale) }
    </option>
  )
}

Option.propTypes = {
  date: PropTypes.string.isRequired,
}

const Select = ({ className, name, onChange, options, defaultValue, hidden }) => {
  const { t } = React.useContext(GlobalContext)

  if (hidden) return null

  return (
    <label>
      { t(`pages.freeze.form.label_${name}`) }
      <select name={name} className={className} onChange={onChange} defaultValue={defaultValue}>
        <PlaceholderOption type={name} />
        {
          options.map(d => <Option key={d} date={d} />)
        }
      </select>
    </label>
  )
}

Select.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  defaultValue: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
}

Select.defaultProps = {
  defaultValue: '',
}

const Selects = ({ freezePeriod, onChange, startDates, availableMonths, active, maxMonths }) => {
  const beginDates = (startDates) => {
    if (availableMonths > 0) return startDates

    // if user has to available months in the current year, allow setting freeze in next year
    startDates = []

    return Array(moment().month + 1).fill().map((_, i) =>
      moment().add(12, 'month').startOf('year').add(i, 'months')
        .startOf('month')
        .format('YYYY-MM-DD'),
    )
  }

  const stopDates = () => {
    let length = startDates.length
    if (freezePeriod.begin_date) {
      length = availableMonths
      const beginDate = moment(freezePeriod.begin_date)

      // For DE, AU, CH where only three months freeze are allowed per calendar year we want to allow
      // freeze to extend into the next year, so if the allowed freeze period can extend to the end of the
      // current year, add the maxMonths to the length, so the user can also access the next calendar year's
      // allowance. But also limit it to a max of 12 months, so we don't get an overly long list for NO
      const freezeMonth = beginDate.month()

      if (beginDate.year() > moment().year()) {
        length = maxMonths
      } else if (freezeMonth + availableMonths >= 12) {
        length = (12 - freezeMonth + maxMonths) % 12
      }
    }

    // We need to add 1 to the length when adding stopDates for an active freeze period,
    // if not we are of by one.
    if (active) {
      length = length + 1
    }

    // We must ensure that we have a positive value to ensure that we don't fail in the code bellow
    if (length < 0) {
      length = 1
    }

    return Array(length).fill().map((_, i) =>
      moment(active ? new Date() : freezePeriod.begin_date || startDates[0]).add(i, 'months')
        .endOf('month')
        .format('YYYY-MM-DD'),
    )
  }

  const selectClass = (key) =>
    !freezePeriod || !freezePeriod[key] ? 'disabled' : 'date-picker'

  return [
    <Select
      key={'begin'}
      className={selectClass('begin_date')}
      defaultValue={freezePeriod.begin_date}
      name={'begin_date'}
      onChange={onChange}
      options={beginDates(startDates)}
      hidden={active}
    />,
    <Select
      key={'end'}
      className={selectClass('end_date')}
      defaultValue={freezePeriod.end_date}
      name={'end_date'}
      onChange={onChange}
      options={stopDates()}
    />,
  ]
}

const CancelButton = ({ scheduledFreeze, t }) => {
  if (!scheduledFreeze) return null

  return (
    <Link to={'/membership/cancel_freeze' }>
      <ActionButton>
        { t('pages.freeze.form.cancel_button') }
      </ActionButton>
    </Link>
  )
}

CancelButton.propTypes = {
  scheduledFreeze: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const SubmitButton = ({ scheduledFreeze, t, disabled }) => {
  const key = scheduledFreeze ? 'freeze_button_update' : 'freeze_button'

  return (
    <ActionButton type={'submit'} disabled={disabled}>
      { t(`pages.freeze.form.${key}`) }
    </ActionButton>
  )
}

SubmitButton.propTypes = {
  scheduledFreeze: PropTypes.object,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const FreezeForm = ({ onFreeze, startDates, policy, scheduledFreeze }) => {
  const { t } = React.useContext(GlobalContext)
  const [freezePeriod, setFreezePeriod] = React.useState(
    scheduledFreeze || { begin_date: '', end_date: '' },
  )
  const [changed, setChanged] = React.useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    onFreeze(freezePeriod)
  }

  const handleSelectChange = (event) => {
    setChanged(true)
    const { name, value } = event.target

    // Creating a new object from the original freeze period with the new value
    const newFreezePeriod = Object.assign({}, freezePeriod, { [name]: value })

    // If the begin date gets changed to a date after end date,
    // then we change the end date to end of month by default
    // this is to fix an error where it appeared for the user that the end date had changed,
    // but we actually ended up creating a freeze period with end date before begin date.
    if (name === 'begin_date' && freezePeriod.end_date && value > freezePeriod.end_date) {
      newFreezePeriod.end_date = moment(value).endOf('month').format('YYYY-MM-DD')
    }

    // Update state
    setFreezePeriod(newFreezePeriod)
  }

  const disabled = () =>
    !changed || !freezePeriod.end_date || !freezePeriod.begin_date

  return (
    <>
      <form onSubmit={onSubmit} className={'full-width block'}>
        <Selects
          freezePeriod={freezePeriod}
          active={policy.membership.current_freeze}
          onChange={handleSelectChange}
          startDates={startDates}
          availableMonths={policy.membership.available_months}
          maxMonths={policy.max_months}
        />
        <SubmitButton
          scheduledFreeze={scheduledFreeze}
          t={t}
          disabled={disabled()}
        />
        <CancelButton
          scheduledFreeze={scheduledFreeze}
          t={t}
        />
      </form>
    </>
  )
}

FreezeForm.propTypes = {
  onFreeze: PropTypes.func.isRequired,
  startDates: PropTypes.array.isRequired,
  policy: PropTypes.object.isRequired,
  scheduledFreeze: PropTypes.object,
}

export default FreezeForm
