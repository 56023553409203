import React from 'react'
import PropTypes from 'prop-types'
import { ActionButton } from 'components/Buttons'
import { objByStringKey } from 'helpers'
import { GlobalContext } from 'components/context'

const Divider = () =>
  <div className={'divider'}>&nbsp;</div>

const UpdateProfileView = ({ onChange, onSubmit, profile }) => {
  const { t } = React.useContext(GlobalContext)

  // force emails to be lowercase when submitted
  const onFocusOut = (e) => {
    if (e.target.name === 'email') {
      e.target.value = e.target.value.toLowerCase()
    }
  }

  const label = (field, txt = null) =>
    <label className={field}>
      { txt ? txt.replace(/ /g, '\u00a0') : t(`forms.labels.${field}`) }
      <input
        type={'text'}
        name={field}
        defaultValue={objByStringKey(profile, field)}
        required
        onBlur={onFocusOut}
      />
    </label>

  return (
    <div id={'profile'}>
      <h1>{ t('pages.profile.title') }</h1>
      <div className={'full-width block'}>
        <form onChange={onChange} onSubmit={onSubmit}>
          <div className={'form-grid'}>
            { label('address.street') }
            { label('address.postal_code') }
            <Divider />
            { label('address.postal_location') }
            { label('mobile.prefix', t('forms.labels.mobile')) }
            <Divider />
            { label('mobile.number', ' ') }
            { label('email') }
          </div>
          <ActionButton type={'submit'}>
            { t('pages.profile.button') }
          </ActionButton>
        </form>
      </div>
    </div>
  )
}

UpdateProfileView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
}

export default UpdateProfileView
